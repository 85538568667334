import { saveAs } from "file-saver";
import { toastSuccess } from "./toast";

export const downloadImages = (s3Urls) => {
  toastSuccess(
    s3Urls.length === 1 ? `מוריד את התמונה שלך!` : "מוריד התמונות שלך!",
    { autoClose: 1000 }
  );

  s3Urls.forEach((s3Url) => {
    saveAs(s3Url, "From PicMe.cloud with ❤️.jpeg");
  });

  // try {
  //   // Fetch the image data from the S3 URL
  //   const response = await fetch(s3Url);
  //   const blob = await response.blob();
  //   console.log("blob", blob);
  //
  //   // Use FileSaver.js to save the blob as a file
  //   saveAs(blob, "image.jpeg");
  // } catch (error) {
  //   console.error("Error downloading image:", error);
  // }
};
