import React, { useState } from "react";
import { Masonry } from "masonic";
import { toastSuccess } from "./toast";
import useFetch from "./useFetch";
import { Link, useNavigate } from "react-router-dom";

const EventAlbumImages = ({ albumImages }) => {
  const navigate = useNavigate();

  const [removedImages, setRemovedImages] = useState([]);
  const removeImageFromClient = (imageId) => {
    setRemovedImages(removedImages.concat(imageId));
  };
  return (
    <>
      <div className="pb40 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img className="camera-icon ml16" src="/camera.svg" alt="camera" />
          <span className="font-xxl bold purple-dark-text pl-text">
            אלבום האירוע
          </span>
        </div>
        <Link
          className="d-flex align-items-center"
          to={".."}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <button className="ml16 inline-button font-xl">
            חזרה לעמוד האירוע
          </button>
          <img src="/arrow-fill.svg" alt="arrow" />
        </Link>
      </div>
      <div className="flex-wrapd-flex-mobile">
        <div className={"masonic-container"}>
          <div className={"masonic"}>
            <Masonry
              // Provides the data for our grid items
              items={albumImages}
              // Adds 8px of space between the grid cells
              columnGutter={16}
              // Sets the minimum column width to 172px
              columnWidth={350}
              // Pre-renders 5 windows worth of content
              overscanBy={5}
              // This is the grid item component
              render={({ data }) => (
                <AlbumImageItem
                  data={data}
                  removeImageFromClient={removeImageFromClient}
                  removedImages={removedImages}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const AlbumImageItem = ({ data, removeImageFromClient, removedImages }) => {
  const {
    fetchData: removeAlbumImage,
    error,
    loading,
  } = useFetch({
    method: "post",
    onSuccess: (resJson) => {
      removeImageFromClient(data._id);
      toastSuccess(`התמונה הוסרה מאלבום האירוע`);
    },
  });
  const removeImageFromAlbum = (e) => {
    e.stopPropagation();
    e.preventDefault();

    removeAlbumImage({
      query: "removeImageFromAlbum",
      params: {
        albumImageId: data._id,
      },
    });
  };

  const imageRemoved = removedImages.includes(data._id);
  return (
    <div
      className={`masonic-card ${imageRemoved ? "masonic-opacity" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <img className={"masonic-card-img"} alt={data.url} src={data.url} />
      {imageRemoved ? null : (
        <div className="masonic-card-action">
          <div onClick={removeImageFromAlbum} className="remove-button">
            הסר
          </div>
        </div>
      )}
    </div>
  );
};
export default EventAlbumImages;
