import React from "react";

const PicMeText = () => {
  return (
    <span className="ltr">
      <span className="purple-text">Pic</span>
      <span>Me</span>
    </span>
  );
};

export default PicMeText;
