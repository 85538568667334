import React from "react";

import useFetch from "./useFetch";
import { toastSuccess } from "./toast";
import SendBroadcastPopupButton from "./SendBroadcastPopupButton";

import SearchResults from "./SearchResults";

import "./SearchResults.css";

const EventSearchResults = ({ event }) => {
  const { loading: sendingImages, fetchData: sendMatches } = useFetch({
    onSuccess: (resJson) => {
      const { imagesSentCount, selfiesCount } = resJson || {};
      toastSuccess(
        `נשלחו ${imagesSentCount || 0} תמונות ל-${selfiesCount || 0} אורחים.`
      );
    },
  });

  // const { fetchData: keepTop5Fetch } = useFetch();
  // const keepTop5 = () => {
  //   keepTop5Fetch({
  //     query: "keepTop5",
  //     params: {
  //       eventId: event._id,
  //     },
  //   });
  // };

  return (
    <>
      {process.env.NODE_ENV === "development" ? (
        <div className="pb40 page-section-padding center">
          {/*<SendBroadcastPopupButton event={event} />*/}
          {/* <button className="mh24" onClick={keepTop5}> */}
          {/*   🧹Top 5🧹 */}
          {/* </button> */}
          <span
            className={`mr24 loading-circle ${
              sendingImages ? "" : "loading-circle-hidden"
            }`}
          ></span>
          {/* <button className="mh24" onClick={addWaterMark}> */}
          {/*   🌠 addWaterMark 🌠 */}
          {/* </button> */}
        </div>
      ) : null}
      {event?.selfieCount ? (
        <div className="page-content page-section-padding d-flex-column">
          <SearchResults eventId={event._id} />
        </div>
      ) : null}
    </>
  );
};

export default EventSearchResults;
