import React from "react";

import { LoginForm } from "./Login";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const NavbarLoginButton = () => {
  return (
    <>
      <Popup
        className="small-popup"
        trigger={
          <button className="picme-button-flat navbar-profile-button">
            התחברות
          </button>
        }
        modal
      >
        {(close) => <LoginForm onFinish={close} />}
      </Popup>
    </>
  );
};

export default NavbarLoginButton;
