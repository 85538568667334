import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import mobile from "is-mobile";

import LoadingSection from "./LoadingSection";
import CreateEventPopupButton from "./CreateEventPopupButton";
import "./EventsPage.css";
import EventStatusBadge from "./EventStatusBadge";
import Page from "./Page";
import useFetch from "./useFetch";
import { shortDate } from "./date-helpers";

const EventsPage = () => {
  const [events, setEvents] = useState([]);

  const { fetchData, data, loading } = useFetch();

  useEffect(() => {
    fetchData({
      query: "getMyEvents",
    });
  }, []);

  useEffect(() => {
    setEvents(data?.events);
  }, [data]);

  return (
    <Page withContactUs>
      <CreateEventSection />
      {loading ? (
        <LoadingSection />
      ) : (
        <>
          <EventsSection events={events} />
        </>
      )}
    </Page>
  );
};

const EventsSection = ({ events }) => (
  <div className="EventsSection p24 mv40">
    {events?.length ? (
      <div className="EventsGrid">
        {events?.map((event) => (
          <EventItem key={event._id} event={event} />
        ))}
      </div>
    ) : (
      <div className="font-l">אין אירועים להצגה</div>
    )}
  </div>
);

const CreateEventSection = () => {
  const [me, setMe] = useState();
  const { fetchData: getMe } = useFetch({
    onSuccess: ({ user }) => setMe(user),
  });

  useEffect(() => {
    getMe({ query: "getMe" });
  }, []);

  return (
    <div className="page-section-padding mb24">
      {mobile() ? null : (
        <div className="pb40 d-flex align-items-center">
          <img className="camera-icon ml16" src="/camera.svg" alt="camera" />
          <span className="font-xl bold purple-dark-text">האירועים שלי</span>
        </div>
      )}
      <div
        className={`d-flex align-items-center  ${
          mobile() ? "justify-content-center" : "justify-content-between"
        }`}
      >
        {mobile() ? null : (
          <img
            className="create-event-image hidden"
            src="/wedding.svg"
            alt="wedding"
          />
        )}

        <div
          className="buttonBox"
          style={{ backgroundImage: "url(/pricing-big-box.svg)" }}
        >
          <div>
            <div className="d-flex-mobile align-items-center justify-content-center">
              <div
                className={`purple-dark-text create-event-title bold ${
                  mobile() ? "pb24" : "pl16"
                }`}
              >
                האירוע של
              </div>
              <img
                className="create-event-logo"
                src="/picme-logo.svg"
                alt="logo"
              />
            </div>
            <div className="pt32 d-flex align-items-center justify-content-center">
              {mobile() ? null : (
                <img
                  className="pl12 icon"
                  alt={"star"}
                  src={"./star-fill.svg"}
                />
              )}
              <div className={mobile() ? "" : "ph32"}>
                <div className="center font-xl">
                  <span>מאפשר לאורחים לקבל את התמונות</span>
                </div>
                <div className="center font-xl pt12">
                  <span>שלהם בקליק דרך לינק ייעודי לשיתוף</span>
                </div>
              </div>
              {mobile() ? null : (
                <img
                  className="pl12 icon"
                  alt={"star"}
                  src={"./star-fill.svg"}
                />
              )}
            </div>
          </div>
          <div className="pt40 d-flex justify-content-center">
            <CreateEventPopupButton me={me} />
          </div>
        </div>
        {mobile() ? null : (
          <div className="d-flex justify-content-end">
            <img
              className="create-event-image"
              src="/wedding.svg"
              alt="wedding"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const PreiviewEventSection = () => {
  return (
    <div className="full-width d-flex align-items-center justify-content-around">
      <div className="buttonBox">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="font-xl">אירוע קרוב</div>
            <div className="font-xl">12/3/23</div>
          </div>
          <div className="font-l pt44">
            <span>האירוע של</span>
            <span className="ph4 bold">שיר ושירה</span>
            <span>מתקרב ובא!</span>
          </div>

          <div className="font-l pt12">
            <span>עד כה האורחים שלחו</span>
            <span className="ph4 bold">0</span>
            <span>סלפים</span>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button>לעמוד האירוע</button>
        </div>
      </div>
      <div className="styleBox" />
    </div>
  );
};

const EventItem = ({ event }) => {
  return (
    <Link
      style={{ textDecoration: "none" }}
      className="EventItem"
      to={{
        pathname: `/event`,
        search: `eventId=${event._id}`,
      }}
    >
      <div className="full-height d-flex-column justify-content-between align-items-center">
        <EventStatusBadge status={event.status} />
        <div className="bold font-xl">{event.name}</div>
        <div className="pb4 font-l">{shortDate(event.date)}</div>
      </div>
    </Link>
  );
};

export default EventsPage;
