import React from "react";
import { StickyContainer, Sticky } from "react-sticky";

import ScrollToTop from "./ScrollToTop";
import Navbar from "./Navbar";
import ContactUs from "./ContactUs";

const Page = ({ children, className, withContactUs }) => {
  return (
    <div className="Page">
      <StickyContainer>
        <Sticky>
          {({ style, ...rest }) => (
            <Navbar style={{ ...style, width: "100%" }} {...rest} />
          )}
        </Sticky>
        <ScrollToTop />
        <div className={`page-content ${className || ""}`}>{children}</div>
        {withContactUs && <ContactUs />}
      </StickyContainer>
    </div>
  );
};

export default Page;
