import { useState } from "react";

import { LOGIN_TOKEN_KEY } from "./Login";

import axios from "axios";

const useFetch = ({
  method = "get",
  onError,
  onSuccess,
  onUploadProgress,
} = {}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState();

  const [progress, setProgress] = useState(0);

  const fetchData = ({ query, params = {}, body }) => {
    setLoading(true);
    var queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/${query}?${queryString}`, {
      method,
      ...(body && { body }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem(LOGIN_TOKEN_KEY)}`,
      },
    })
      .then(async (res) => {
        const resJson = await res.json();
        if (res.status === 200 && !resJson.error) {
          setData(resJson);
          onSuccess && onSuccess(resJson);
        } else {
          console.log("err", resJson.error);
          setError(resJson.error);
          onError && onError(resJson.error);
        }
      })
      .catch((err) => {
        console.log("CLIENT error", err);
        setError("CLIENT error" + err);
        onError && onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const upload = ({ query, params = {}, body }) => {
    setLoading(true);

    var queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios
      .post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/${query}?${queryString}`,
        body,
        {
          headers: {
            // Content-Type => content-type
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(LOGIN_TOKEN_KEY)}`,
          },
          // maxBodyLength: 104857600 * 10 * 10, //100mb * 10 * 10
          // maxContentLength: 104857600 * 10 * 10, //100mb * 10 * 10
          onUploadProgress: (progressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        }
      )
      .then(async (resJson) => {
        if (resJson.status === 200 && !resJson.error) {
          setData(resJson);
          onSuccess && onSuccess(resJson);
        } else {
          console.log("err", resJson.error);
          setError(resJson.error);
          onError && onError(resJson.error);
        }
      })
      .catch((err) => {
        console.log("CLIENT error", err);
        setError("CLIENT error" + err);
        onError && onError(err);
      })
      .finally(() => {
        setLoading(false);
        setProgress(0);
      });
  };

  return { fetchData, data, loading, error, upload, progress };
};

export default useFetch;
