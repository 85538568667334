import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import mobile from "is-mobile";

import Input from "./Input";
import Page from "./Page";
import PicMeBanner from "./PicMeBanner";
import useFetch from "./useFetch";
import HowItWorksSection from "./HowItWorksSection";
import LoginPackagesSection from "./LoginPackagesSection";
import CloseIcon from "./CloseIcon";
import WhatsAppSection from "./WhatsAppSection";

export const LOGIN_TOKEN_KEY = "login-token";
export const USER_NAME = "user-name";
export const USER_ID = "user-id";
export const USER_PHONE = "user-phone";
export const USER_ROLE = "user-role";

export const onLogout = () => {
  localStorage.removeItem(LOGIN_TOKEN_KEY);
  localStorage.removeItem(USER_NAME);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(USER_PHONE);
};

const onLogin = (token, userName, userId, userPhone, userRole) => {
  localStorage.setItem(LOGIN_TOKEN_KEY, token);
  localStorage.setItem(USER_NAME, userName);
  localStorage.setItem(USER_ID, userId);
  localStorage.setItem(USER_PHONE, userPhone);
  localStorage.setItem(USER_ROLE, userRole);
};

const Login = () => {
  return (
    <Page withContactUs>
      <div className="page-section-content page-section-padding d-flex-mobile align-items-center justify-content-between">
        <PicMeBanner />
      </div>

      <HowItWorksSection />
      <WhatsAppSection />
      <LoginPackagesSection />
    </Page>
  );
};

export const LoginForm = ({ onFinish }) => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState(
    process.env.NODE_ENV === "development" ? "0545874925" : ""
  );
  const [password, setPassword] = useState(
    process.env.NODE_ENV === "development" ? "88888888" : ""
  );

  const [isAprovedTerms, setIsAprovedTerms] = useState(
    process.env.NODE_ENV === "development"
  );

  const {
    fetchData: login,
    error,
    loading,
  } = useFetch({
    method: "post",
    onSuccess: (resJson) => {
      if (resJson.token) {
        onLogin(
          resJson.token,
          resJson.userName,
          resJson.userId,
          resJson.userPhone,
          resJson.userRole
        );
        navigate(`/`, { replace: true });
      }
    },
  });
  const onSubmit = (e) => {
    e.preventDefault();

    login({
      query: "login",
      params: {
        password,
        phone,
      },
    });

    window.scrollTo({
      top: 0,
    });
  };

  return (
    <form className="login-form" onSubmit={onSubmit}>
      <button
        type="button"
        className="popup-close-icon inline-button mr12"
        onClick={onFinish}
      >
        <CloseIcon />
      </button>
      <img className="logo-icon mb40" src="/picme-logo.svg" alt="logo" />
      <div className="font-l pb32">כניסה לצלמים רשומים</div>

      <div>
        <Input label="טלפון" value={phone} setValue={setPhone} />
        <Input
          type="password"
          label="סיסמא"
          value={password}
          setValue={setPassword}
        />

        <div className="d-flex align-items-center">
          <input
            autoFocus={true}
            className="checkbox"
            type="checkbox"
            id="aproveTerms"
            name="aproveTerms"
            checked={isAprovedTerms}
            onChange={(e) => {
              setIsAprovedTerms(e.target.checked);
            }}
          ></input>
          <label htmlFor="aproveTerms">
            <span>אני מאשר/ת את </span>
            <a href="/terms-site" target="_blank" rel="noopener noreferrer">
              תקנון השימוש
            </a>
          </label>
        </div>
      </div>
      <div>
        <div className="form-error">{error}</div>
        <button
          className="picme-button d-flex align-items-center"
          type="submit"
          disabled={!phone || !password || !isAprovedTerms || loading}
        >
          <span> התחברות</span>
          {loading && <span className="mr12 loading-circle"></span>}
        </button>
      </div>
    </form>
  );
};

export default Login;
