import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import mobile from "is-mobile";
import { Line } from "rc-progress";

import LoadingSection from "./LoadingSection";
import "./EventsPage.css";
import Page from "./Page";
import useFetch from "./useFetch";
import Input from "./Input";
import { toastError, toastSuccess } from "./toast";

import "./UserPage.css";
import Toggle from "./Toggle";
import { UserCardForGuest } from "./SelfiePics";
import StarIcon from "./StarIcon";
import parsePhoneNumber from "libphonenumber-js";

const MAX_FILE_SIZE = 1024 * 1024 * 30;

const UserPage = () => {
  const [user, setUser] = useState();

  const { fetchData, loading } = useFetch({
    onSuccess: (resJson) => {
      const { user: _user } = resJson || {};
      setUser(_user);
    },
  });

  useEffect(() => {
    fetchData({
      query: "getMe",
    });
  }, []);

  return (
    <Page withContactUs>
      {loading ? (
        <LoadingSection />
      ) : user ? (
        <div className="page-section-content page-section-padding">
          <div className="d-flex align-items-center pb32">
            <img className="camera-icon ml16" src="/user-icon.svg" alt="user" />
            <span className="font-xxl bold purple-dark-text">הגדרות משתמש</span>
          </div>
          <div className="d-flex-mobile justify-content-between">
            <div className="flex-1">
              <UserGeneralInfo user={user} setUser={setUser} />
              <UserCardPreview user={user} />
            </div>
            <UserCard user={user} setUser={setUser} />
          </div>
        </div>
      ) : (
        <div>משתמש לא נמצא</div>
      )}
    </Page>
  );
};

const UserGeneralInfo = ({ user }) => (
  <div
    className="border-radius mb40 height-max-content user-details"
    style={{ backgroundImage: "url(/pricing-big-box.svg)" }}
  >
    <div className="font-xl">
      <div className="purple-dark-text center bold p32">פרטי משתמש</div>
      <div className="p32 bt center">
        <span className="pl-text">שם:</span>
        <span>{user.name}</span>
      </div>
      <div className="p32 bt center">
        <span className="pl-text">טלפון:</span>
        <span>
          {parsePhoneNumber(user.phone, "IL")?.formatNational() || ""}
        </span>
      </div>
    </div>
  </div>
);

const UserCardPreview = ({ user }) => {
  const withCard =
    user.card?.imageLink ||
    user.card?.name ||
    user.card?.phone ||
    user.card?.facebookLink ||
    user.card?.instagramLink ||
    user.card?.youtubeLink ||
    user.card?.vimeoLink;
  return withCard ? (
    <div className="card-preview-wrapper">
      <div className="card-preview-wrapper-header">תצוגה מקדימה</div>
      <UserCardForGuest card={user.card} />
    </div>
  ) : null;
};
const UserCard = ({ user, setUser }) => {
  const [name, setName] = useState(user.card?.name || "");
  const [phone, setPhone] = useState(user.card?.phone || "");
  const [webLink, setWebLink] = useState(user.card?.webLink || "");
  const [imageLink, setImageLink] = useState(user.card?.imageLink || "");
  const [vimeoLink, setVimeoLink] = useState(user.card?.vimeoLink || "");
  const [youtubeLink, setYoutubeLink] = useState(user.card?.youtubeLink || "");
  const [isEnabled, setIsEnabled] = useState(user.card?.isEnabled || false);
  const [facebookLink, setFacebookLink] = useState(
    user.card?.facebookLink || ""
  );
  const [instagramLink, setInstagramLink] = useState(
    user.card?.instagramLink || ""
  );

  const {
    fetchData: updateMyUserData,
    error,
    loading,
  } = useFetch({
    method: "post",
    onSuccess: (resJson) => {
      toastSuccess("הפרטים עודכנו בהצלחה!");
      const { user: updatedUser } = resJson || {};
      if (updatedUser) {
        setUser({ ...user, ...updatedUser });
      }
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();

    updateMyUserData({
      query: "updateMyUserCard",
      params: {
        name,
        phone,
        facebookLink,
        webLink,
        instagramLink,
        vimeoLink,
        youtubeLink,
        isEnabled,
      },
    });
  };

  return (
    <div
      className="UserCardForm border-radius"
      style={{ backgroundImage: "url(/pricing-big-box.svg)" }}
    >
      <div className="ph32 pt32 pb24 d-flex align-items-center font-xl bold purple-dark-text">
        <StarIcon light={true} />
        <div>כרטיס הביקור שלי</div>
      </div>
      <div className="ph32 font-l bb pb40">
        פרסם את עצמך בכל אירוע! כרטיס הביקור יוצג לכל האורחים שקיבלו תמונות ממך.
      </div>

      <form className="font-l ph32 pt40 pb40 mb40 bb" onSubmit={onSubmit}>
        <Input label="שם הסטודיו" value={name} setValue={setName} />

        <div className="d-flex-mobile">
          <Input
            className={`mb24 ${mobile() ? "" : "ml24"}`}
            label="טלפון"
            value={phone}
            setValue={setPhone}
          />
          <Input
            className="mb24"
            label="אתר"
            value={webLink}
            setValue={setWebLink}
          />
        </div>
        <div className="d-flex-mobile">
          <Input
            className={`mb24 ${mobile() ? "" : "ml24"}`}
            label="פייסבוק"
            value={facebookLink}
            setValue={setFacebookLink}
          />
          <Input
            className="mb24"
            label="אינסטגרם"
            value={instagramLink}
            setValue={setInstagramLink}
          />
        </div>
        <div className="d-flex-mobile">
          <Input
            className={`mb24 ${mobile() ? "" : "ml24"}`}
            label="יוטיוב"
            value={youtubeLink}
            setValue={setYoutubeLink}
          />
          <Input
            className="mb24"
            label="Vimeo"
            value={vimeoLink}
            setValue={setVimeoLink}
          />
        </div>
        <div className="d-flex-mobile justify-content-between align-items-center pt24">
          <Toggle
            toggleId={"userCard"}
            className={"d-flex align-items-center disable-select"}
            isChecked={isEnabled}
            onClick={() => setIsEnabled(!isEnabled)}
            customLabelContent={
              <div className={`thin pr12`}>
                <div
                  className={`font-xl ${
                    isEnabled ? "purple-text" : "grey-text"
                  }`}
                >
                  {isEnabled ? "כרטיס הביקור פעיל" : "כרטיס הביקור לא פעיל"}
                </div>
              </div>
            }
          />
          <button
            type="submit"
            disabled={loading}
            className={mobile() ? "mt24" : ""}
          >
            <span>שמור</span>
            {loading && <span className="mr12 loading-circle"></span>}
          </button>
        </div>
      </form>
      <div className="ph32 pb40">
        <UploadUserImage
          imageLink={imageLink}
          setImageLink={(newImageLink) => {
            setImageLink(newImageLink);
            setUser({
              ...user,
              card: { ...user.card, imageLink: newImageLink },
            });
          }}
        />
      </div>
    </div>
  );
};

const UploadUserImage = ({ imageLink, setImageLink }) => {
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(async (files) => {
    const file = files?.[0];
    if (!file) {
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      toastError("לא ניתן להעלות תמונה בגודל שעולה על 30MB");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    console.log("file", file);
    formData.append("images", file);
    // Modify the uploadImages function to handle individual requests
    await uploadUserImage({
      query: "uploadUserImage",
      body: formData,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png", ".JPEG", ".PNG"],
    },
  });

  const { upload: uploadUserImage, progress } = useFetch({
    method: "PUT",
    onSuccess: async (resJson, uploadData) => {
      if (resJson.data.error) {
        toastError(resJson.data.error);
        return;
      }
      toastSuccess(`התמונות הועלו בהצלחה`);

      setImageLink(resJson.data.imageLink);

      setUploading(false);
    },
    onError: (e) => {
      console.log("ERROR IN IMAGES UPLOAD", e);
      toastError("קרתה שגיאה בהעלאת התמונות", { disableAutoClose: true });
      setUploading(false);
    },
  });

  const { fetchData: removeUserImage, loading: removing } = useFetch({
    method: "post",
    onSuccess: () => {
      toastSuccess("הלוגו הוסר בהצלחה");
      setImageLink("");
    },
  });
  const removeImage = async () => {
    await removeUserImage({ query: "removeUserImage" });
  };

  return (
    <div>
      <div className="UploadImagesWidget" {...getRootProps()}>
        <input {...getInputProps()} accept="image/png" />
        <div className="font-l d-flex align-items-center justify-content-center">
          {uploading ? (
            <div className="d-flex-column">
              <div className="d-flex align-items-center mb24">
                <span className="pl12">מעלה את התמונה</span>
                <span>{`${progress || 0}%`}</span>
              </div>

              <Line
                percent={progress || 0}
                strokeWidth={8}
                trailWidth={8}
                strokeColor="#7f52ff"
              />
            </div>
          ) : isDragActive ? (
            <div>שחרר את הלוגו</div>
          ) : imageLink ? (
            <img
              className={"masonic-card-img full-height"}
              src={imageLink}
              alt={imageLink}
            />
          ) : (
            <div className="d-flex-column justify-content-center align-items-center">
              <div className="pb32">
                <img src="upload.svg" alt="upload" />
              </div>
              <div className="pb24">גרור לכאן לוגו</div>
              <div className="">או לחץ להעלאה</div>
            </div>
          )}
        </div>
      </div>
      <div className="pt24 d-flex-mobile justify-content-end">
        <button disabled={removing} onClick={removeImage}>
          <span>הסר לוגו</span>
          {removing && <span className="mr12 loading-circle"></span>}
        </button>
      </div>
    </div>
  );
};

export default UserPage;
