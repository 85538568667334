import React from "react";

import "./ContactUs.css";
import mobile from "is-mobile";

const ContactUs = () => {
  return (
    <div className="page-section-padding d-flex-mobile ContactUs-section d-flex pb40 pt40">
      <div className="full-width">
        <div className="ContactUs-line bold">צרו קשר</div>

        <WhatsappContactLink />

        <div className="ContactUs-line">
          <img className="icon pl12" alt={"email"} src={"./email.svg"} />
          <span className="ph12">{"picme.contactus@gmail.com"}</span>
        </div>
      </div>
      <div className="full-width">
        {mobile() ? null : (
          <div className="ContactUs-line bold">פרגנו בלייק</div>
        )}

        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.facebook.com/profile.php?id=100095689325007"
          className="Link ContactUs-line"
        >
          <img
            className="icon pl12"
            alt={"facebook"}
            src={"./facebook-icon.svg"}
          />
          <span className="ph12">{"facebook.com/PicMe"}</span>
        </a>

        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.instagram.com/picmee.co.il"
          className="Link ContactUs-line"
        >
          <img
            className="icon pl12"
            alt={"instagram"}
            src={"./instagram-icon.svg"}
          />
          <span className="ph12">{"instagram.com/picmee.co.il"}</span>
        </a>
      </div>

      <img
        className={`ContactUs-logo ${mobile() ? "pt24" : ""}`}
        src="/picme-logo.svg"
        alt="logo"
      />
    </div>
  );
};

export const WhatsappContactLink = () => (
  <a
    rel="noreferrer"
    target="_blank"
    href="https://api.whatsapp.com/send?phone=972555524778"
    className="Link ContactUs-line "
  >
    <img className="icon pl12" alt={"whatsapp"} src={"./whatsapp.svg"} />

    <span className="ph12">{"055-5524778"}</span>
  </a>
);

export default ContactUs;
