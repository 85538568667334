import React from "react";

import mobile from "is-mobile";
import PicMeText from "./PicMeText";
import LeadPopupButton from "./LeadPopupButton";
import "./PicMeBanner.css";

const PicMeBanner = ({ withNewEventButton }) => {
  return (
    <div className="PicMeBanner d-flex-mobile justify-content-between">
      <div className={mobile() ? "d-flex-mobile align-items-center" : ""}>
        <img className="picme-banner-logo" src="/picme-logo.svg" alt="banner" />
        <div className="banner-header">
          <span className="banner-header-word">והאורחים</span>
          <span className="banner-header-word pr-text"> מקבלים</span>
          <span className="banner-header-word pr-text"> את</span>
          <span className="banner-header-word">
            <span className="pr-text"> התמונות</span>
            <span className="pr12 purple-text gevert-levin-text">בקליק!</span>
          </span>
        </div>
        <div className="banner-text">
          <div className="pb12">האורחים שולחים סלפי דרך וואטסאפ</div>
          <div
            className={`pb12 flex-wrap d-flex align-items-center ${
              mobile() ? "justify-content-center" : ""
            }`}
          >
            <PicMeText />
            <span className="pr-text">שולח</span>
            <span className="pr-text">להם</span>
            <span className="pr-text">בחזרה</span>
            <span className="pr-text">רק</span>
            <span className="pr-text">את</span>
            <span className="pr-text">התמונות</span>
            <span className="pr-text">שלהם!</span>
          </div>
        </div>
        <LeadPopupButton />
      </div>

      {mobile() ? null : (
        <div>
          <img
            className="picme-banner-image"
            src="/hero-image.svg"
            alt="banner"
          />
        </div>
      )}
    </div>
  );
};

export default PicMeBanner;
