import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import parsePhoneNumber from "libphonenumber-js";
import mobile from "is-mobile";

import "./MenuPopup.css";

import { onLogout } from "./Login";
import NavbarLoginButton from "./NavbarLoginButton";
import useFetch from "./useFetch";

import { LOGIN_TOKEN_KEY, USER_NAME, USER_PHONE } from "./Login";
import CloseIcon from "./CloseIcon";

const Navbar = React.forwardRef(({ style, ...rest }, ref) => {
  const location = useLocation();

  const loginToken = localStorage.getItem(LOGIN_TOKEN_KEY);
  const userName = localStorage.getItem(USER_NAME);
  const userPhone = localStorage.getItem(USER_PHONE);

  const { fetchData } = useFetch({
    onSuccess: (resJson) => console.log(resJson),
  });

  const test = () => {
    fetchData({ query: "test" });
  };

  return loginToken ? (
    <div className="Navbar" ref={ref} style={style}>
      <div className="navbar-item">
        <NavMenu userName={userName} userPhone={userPhone} />
      </div>

      <div className="navbar-logo">
        {process.env.NODE_ENV === "development" ? (
          <>
            <button className="inline-button mh24" onClick={test}>
              Dev Action
            </button>
            <a
              className="mh24"
              href="http://localhost:8000/dash/"
              target="_blank"
              rel="noreferrer"
            >
              Agenda
            </a>
          </>
        ) : null}

        <Link
          className="navbar-item"
          to={{
            pathname: "/",
          }}
        >
          <img className="navbar-logo-icon" src="/picme-logo.svg" alt="logo" />
        </Link>
      </div>
    </div>
  ) : (
    <div className="Navbar" ref={ref} style={style}>
      <div className="navbar-item">
        {location.pathname === "/login" ? <NavbarLoginButton /> : null}
      </div>

      <Link
        className="navbar-item"
        to={{
          pathname: "/",
        }}
      >
        <div className="navbar-logo">
          <img className="navbar-logo-icon" src="/picme-logo.svg" alt="logo" />
        </div>
      </Link>
    </div>
  );
});

const LogoutButton = () => {
  const navigate = useNavigate();
  return (
    <div className="">
      <Link
        onClick={() => {
          onLogout();
          navigate(0);
        }}
        className="Link d-flex align-items-center"
      >
        <button className="bg-white black-button navbar-profile-icon">
          התנתקות
        </button>
      </Link>
    </div>
  );
};
const NavMenu = ({ userName, userPhone }) => {
  let navigate = useNavigate();

  const phoneNumber = parsePhoneNumber(userPhone, "IL")?.formatNational() || "";

  return (
    <Popup
      className="menu-popup"
      trigger={
        <div className="navbar-profile-icon d-flex align-items-center font-l pointer">
          <img className="pl16" alt={"menu"} src={"./burger_filled.svg"} />
          <span className="pl-text">שלום,</span>
          <span>{userName}</span>
        </div>
      }
      position="center top"
      offsetX={mobile() ? -12 : 72}
      offsetY={mobile() ? -8 : -8}
      on="hover"
      closeOnDocumentClick
      mouseLeaveDelay={250}
      mouseEnterDelay={0}
      arrow={false}
    >
      {(close) => (
        <div className="NavMenu thin font-l">
          <div className="NavMenu-header regular">
            <img className="pl16" alt={"menu"} src={"./burger.svg"} />
            <span className="pl-text">שלום,</span>
            <span>{userName}</span>

            {mobile() ? (
              <button
                className="popup-close-icon inline-button mr12"
                onClick={close}
              >
                <CloseIcon />
              </button>
            ) : null}
          </div>
          <div className="NavMenu-profile-section">
            <img className="mb12" alt={"profile"} src={"./profile.svg"} />
            <div>{phoneNumber}</div>
          </div>
          <div className="NavMenu-item" onClick={() => navigate("/me")}>
            <StarMenuIcon />
            <span>הגדרות משתמש</span>
          </div>
          <div className="NavMenu-item" onClick={() => navigate("/")}>
            <StarMenuIcon />
            <span>האירועים שלי</span>
          </div>
          <div className="NavMenu-item" onClick={() => navigate("/support")}>
            <StarMenuIcon />
            <span>סרטון הדרכה</span>
          </div>
          <div
            className="NavMenu-logout regular pointer"
            onClick={() => {
              onLogout();
              navigate(0);
            }}
          >
            <span>התנתקות</span>
          </div>
        </div>
      )}
    </Popup>
  );
};

const StarMenuIcon = () => {
  return <img className="pl16 icon" alt={"star"} src={"./star-fill.svg"} />;
};

export default Navbar;
