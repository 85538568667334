import React, { useEffect, useMemo, useState } from "react";
import Page from "./Page";
import { useSearchParams } from "react-router-dom";
import { downloadImages } from "./downloadImages";
import useFetch from "./useFetch";
import { SelfieResult, shouldSendMatch } from "./SearchResults";
import LoadingSection from "./LoadingSection";
import mobile from "is-mobile";
import { LOGIN_TOKEN_KEY } from "./Login";
import { toastSuccess } from "./toast";
import SelfiePics from "./SelfiePics";

const MyPicsPage = ({}) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const eventSlug = searchParams.get("event");

  const [selfie, setSelfie] = useState();

  const { fetchData, data, error, loading } = useFetch();

  useEffect(() => {
    fetchData({
      query: "getSelfieByGuest",
      params: {
        token,
        eventSlug,
      },
    });
  }, []);

  useEffect(() => {
    if (data?.selfie) {
      setSelfie(data.selfie);
    }
  }, [data]);

  const cleanWrongMatch = (_, albumImageKey, remove) => {
    // Optimistic response
    setSelfie({
      ...selfie,
      matches:
        selfie.matches?.map((match) =>
          match.albumImage.key === albumImageKey
            ? { ...match, isRemoved: remove, isAdded: !remove }
            : match
        ) || [],
    });

    fetch(
      `${
        process.env.REACT_APP_SERVER_ENDPOINT
      }/cleanWrongMatchByGuest?token=${token}&selfieId=${
        selfie._id
      }&albumImageKey=${albumImageKey}&revert=${!remove}`
    )
      .then(async (res) => {})
      .catch((err) => console.log("CLIENT error", err))
      .finally(() => {});
  };

  const { loading: sendingImages, fetchData: sendMatches } = useFetch({
    onSuccess: (resJson) => {
      const { imagesSentCount, selfie: _newSelfie } = resJson || {};
      setSelfie({ ...selfie, ..._newSelfie });
      toastSuccess(`נשלחו ${imagesSentCount} תמונות, כמה דקות והן אצלך!`);
    },
  });

  const onSendMatchesClick = () => {
    sendMatches({
      query: "sendMatchesToWhatsappByGuest",
      params: {
        selfieId: selfie._id,
        token,
      },
    });
  };

  const imagesToSend = useMemo(
    () =>
      selfie?.matches
        ?.sort((a, b) =>
          a.distance === b.distance ? 0 : a.distance < b.distance ? -1 : 1
        )
        .filter(({ isAdded, isSent, isRemoved }, index) => {
          return (
            !isSent &&
            shouldSendMatch({
              isRemoved,
              isAdded,
              isSent,
              index,
            })
          );
        }).length,
    [selfie]
  );

  if (loading) {
    return (
      <Page>
        <LoadingSection />
      </Page>
    );
  }

  return (
    <Page>
      <div className="page-section-padding">
        {error && <div className={"center red-text"}>{error}</div>}

        {selfie ? <MyPicsPageTop selfie={selfie} /> : null}
        {selfie && imagesToSend ? (
          <button
            className={`${
              mobile() ? "font-xl" : "font-l"
            } d-flex align-items-center justify-content-center mobile-hover-button`}
            onClick={onSendMatchesClick}
            disabled={sendingImages}
          >
            {sendingImages ? (
              <>
                <span className="pl-text">שולח...</span>
                <span className="mr12 loading-circle"></span>
              </>
            ) : (
              <>
                <span className="pl-text">שלח אליי</span>
                <span className="pl-text">{imagesToSend}</span>
                <span>תמונות לוואטסאפ</span>
              </>
            )}
          </button>
        ) : null}

        <div className="page-content d-flex-column">
          {selfie ? (
            <SelfiePics selfie={selfie} cleanWrongMatch={cleanWrongMatch} />
          ) : null}
        </div>
      </div>
    </Page>
  );
};

const MyPicsPageTop = ({ selfie }) => {
  return (
    <div
      className={
        "d-flex-column align-items-center justify-content-center p16 mb24 border-radius"
      }
      style={{ backgroundImage: "url(/pricing-big-box.svg)" }}
    >
      <div className="center pb16 font-xl purple-dark-text">
        <span className="pl-text">התמונות שלך מהאירוע</span>

        {selfie.eventName ? (
          <>
            <span className="pl-text">של</span>
            <div className="bold">{selfie.eventName}</div>
          </>
        ) : null}
      </div>

      <div className="center font-xl purple-dark-text thin">
        <span className="pl-text">
          בחר.י את התמונות היפות ביותר שלך ושלח.י אותן ישירות לוואטסאפ
        </span>
      </div>
    </div>
  );
};

export default MyPicsPage;
