import React from "react";
import mobile from "is-mobile";

import StarIcon from "./StarIcon";
import UploadImagesWidget from "./UploadImagesWidget";
import CloseIcon from "./CloseIcon";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { EVENT_STATUS } from "./client-consts";
import { Tooltip } from "react-tooltip";
import UploadImagesFromLyncpix from "./UploadImagesFromLyncpix";

const UploadToAlbumPopupButton = ({
  event,
  fetchEventSearchStatus,
  refetchEvent,
}) => {
  const isEventActive = event.status === EVENT_STATUS.ACTIVE;
  return (
    <div className="font-l">
      <Tooltip id="upload" />
      <Popup
        closeOnDocumentClick={false}
        trigger={
          <div
            data-tooltip-id={isEventActive ? null : "upload"}
            data-tooltip-content="יש להפעיל את האירוע על מנת לפתוח את העלאת התמונות"
          >
            <button className="primary-button" disabled={!isEventActive}>
              העלאת תמונות
            </button>
          </div>
        }
        modal
      >
        {(close) => (
          <>
            <button
              className="popup-close-icon inline-button mr12"
              onClick={close}
            >
              <CloseIcon />
            </button>

            <div
              className={`mt24 mb24 d-flex align-items-center ${mobile() ? "justify-content-center" : "justify-content-between"}`}
            >
              {mobile() ? null : (
                <div className="pl24">
                  <div className="d-flex-mobile align-items-center justify-content-between pb40">
                    <img className="ml12" src="arrow-up.svg" alt="upload" />
                    <div
                      className={`bold purple-dark-text flex-1 font-xl ${
                        mobile() ? "center pt24" : ""
                      }`}
                    >
                      {"העלאת תמונות לאלבום האירוע"}
                    </div>
                  </div>
                  <div className="font-l pb24">
                    {"תמונות האלבום הן תמונות שהצלם צילם באירוע."}
                  </div>
                  <div className="font-l thin">
                    <div className="pb12 d-flex align-items-center">
                      <StarIcon />
                      <span>עד 30MB לתמונה</span>
                    </div>
                    <div className="pb12 d-flex align-items-center">
                      <StarIcon />
                      <span>עד 6000 תמונות באלבום</span>
                    </div>
                  </div>
                </div>
              )}
              <UploadImagesWidget
                event={event}
                fetchEventSearchStatus={fetchEventSearchStatus}
                refetchEvent={refetchEvent}
                closePopup={close}
              />
            </div>
            <div className="full-width thin bt pt40 mt40"></div>
            <div className="">
              <div className="d-flex-mobile align-items-center pb24">
                {mobile() ? null : (
                  <img className="ml12" src="arrow-up.svg" alt="upload" />
                )}

                <div
                  className={`bold purple-dark-text font-xl ${
                    mobile() ? "center pb24" : "ml12"
                  }`}
                >
                  {"ייבוא תמונות מ - "}
                </div>
                <img
                  className="pointer"
                  alt={"lyncpix"}
                  src={"./logo-lyncpix.png"}
                  onClick={() => {
                    window.open("https://admin.lyncpix.com/", "_blank");
                  }}
                />
                {mobile() ? null : (
                  <>
                    <Tooltip id="lyncpix-how">
                      לחץ לצפייה בהדרכה - ייבוא תמונות מ Lyncpix
                    </Tooltip>
                    <div
                      className="pointer purple-dark-text mr24"
                      data-tooltip-id="lyncpix-how"
                      onClick={() => window.open("lyncpix-how.png")}
                    >
                      <img
                        className="icon-large"
                        alt={"info"}
                        src={"./info.svg"}
                      />
                    </div>
                  </>
                )}
              </div>

              <UploadImagesFromLyncpix
                event={event}
                fetchEventSearchStatus={fetchEventSearchStatus}
                refetchEvent={refetchEvent}
                closePopup={close}
              />
            </div>
          </>
        )}
      </Popup>
    </div>
  );
};

export default UploadToAlbumPopupButton;
