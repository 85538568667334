import React, { useEffect, useRef, useState } from "react";
import mobile from "is-mobile";

import "./Video.css";
import ReactPlayer from "react-player";

const Video = ({
  url,
  autoPlay = true,
  width,
  height,
  mobileHeight,
  muted = true,
}) => {
  const [playing, setPlaying] = useState(false);

  console.log("height", height);

  const bottomOfVideoRef = useRef(null);
  // useEffect to make the  video play automatically when the
  // video is fully visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (autoPlay) {
              setPlaying(true);
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    if (bottomOfVideoRef.current) {
      observer.observe(bottomOfVideoRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="player-wrapper">
        <ReactPlayer
          playing={playing}
          className="react-player border-radius"
          url={url}
          controls={true}
          volume={0}
          muted={muted}
          width={mobile() ? "100%" : width || "100%"}
          height={mobile() ? mobileHeight || "100%" : height || "auto"}
          loop={true}
        />
      </div>
      <div ref={bottomOfVideoRef}></div>
    </>
  );
};

export default Video;
