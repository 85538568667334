import React from "react";

import PicMeText from "./PicMeText";
import "./HowItWorksSection.css";
import mobile from "is-mobile";

const STEPS = [
  {
    key: 1,
    header: "משתפים",
    body: (
      <div className="flex-wrap">
        <span className="pl-text">
          משתפים עם האורחים את לינק האירוע הפותח את הבוט WhatsApp של
        </span>
        <PicMeText />
        <span className="pr-text">המבקש תמונת סלפי</span>
      </div>
    ),
    imageSrc: "/selfie-image.svg",
  },
  {
    key: 2,
    header: "מעלים",
    body: (
      <div className="flex-wrap">
        <span className="pl-text">
          מעלים את תמונות האירוע לאלבום האירוע באתר של
        </span>
        <PicMeText />
      </div>
    ),

    imageSrc: "/upload-image.svg",
  },
  {
    key: 3,
    header: "נהנים",
    body: (
      <div className="flex-wrap">
        <PicMeText />
        <span>
          {" "}
          באמצעות זיהוי פנים AI שולח לאורחים רק את התמונות שלהם ישירות
          ל-WhatsApp
        </span>
      </div>
    ),
    imageSrc: "/yay-image.svg",
  },
];

const HowItWorksSection = () => {
  return (
    <div className="HowItWorksSection page-section-content d-flex-column bt">
      {mobile() ? null : (
        <div className="bb page-section-big-padding HowItWorksChecks d-flex-mobile align-items-center justify-content-center pv40">
          <div className="HowItWorksChecks-item">
            <span>בוט וואטסאפ לאורחים</span>
          </div>
          <div className="HowItWorksChecks-item d-flex-mobile align-items-center">
            <img
              className="HowItWorksChecks-item-dot"
              src="star.svg"
              alt="star"
            />

            <span>ממשק קל ופשוט לצלם</span>
          </div>
          <div className="HowItWorksChecks-item d-flex-mobile">
            <img
              className="HowItWorksChecks-item-dot"
              src="star.svg"
              alt="star"
            />

            <span>זיהוי פנים באמצעות </span>
            <span className="purple-text pr-text">AI</span>
          </div>
        </div>
      )}
      <div className="flex-1 d-flex align-items-center page-section-big-padding">
        <div className="HowItWorksItems full-width d-flex-mobile align-items-base justify-content-between">
          {STEPS.map(({ key, header, body, imageSrc }) => (
            <div
              key={key}
              className="HowItWorksItem center"
              style={{ backgroundImage: "url(/how-it-works-box.svg)" }}
            >
              <div
                className={
                  mobile() ? "d-flex align-items-center pb24" : "d-flex-column"
                }
              >
                <img
                  className="HowItWorksItem-image"
                  src={imageSrc}
                  alt={imageSrc}
                />
                <div className="HowItWorksItem-header bold">{header}</div>
              </div>
              <div className="HowItWorksItem-body thin">{body}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;
