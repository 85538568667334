import React from "react";
import Page from "./Page";
import StarIcon from "./StarIcon";

import WhatsAppSection from "./WhatsAppSection";
import Video from "./Video";

const SupportPage = () => {
  return (
    <Page withContactUs={true} className="center">
      <div className="page-section-padding mb24">
        <div className="d-flex align-items-center">
          <img
            className="camera-icon ml16"
            src="/youtube-icon.svg"
            alt="camera"
          />
          <span className="font-xxl bold purple-dark-text">
            <span>סרטון הדרכה</span>
            <span> (60 שניות)</span>
          </span>
        </div>

        <div className="font-xl pt40 pb24 d-flex-column align-items-center">
          <div className="pb24">בדקה אחת בלבד תלמדו:</div>
          <div className="d-flex-mobile align-items-center pb40 thin">
            <div> לשתף עם האורחים את לינק האירוע</div>
            <StarIcon light className="pr12 pb12 pt12" />
            <div> להעלות את תמונות האירוע לאתר</div>
            <StarIcon light className="pr12 pb12 pt12" />
            <div> לשלוח את התמונות לאורחים</div>
          </div>
        </div>

        <Video
          autoPlay={false}
          url="https://www.youtube.com/watch/6bRg9jGrI1I"
          height="480px"
          mobileHeight="280px"
          width="840px"
          muted={false}
        />
      </div>

      <WhatsAppSection />
    </Page>
  );
};

export default SupportPage;
