import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingSection from "./LoadingSection";
import useFetch from "./useFetch";

import Page from "./Page";
import "./EventPage.css";
import EventAlbumImages from "./EventAlbumImages";

const EventPage = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");

  const { fetchData, data, error, loading } = useFetch();

  useEffect(() => {
    fetchData({
      query: "getEventAlbumImages",
      params: {
        eventId: eventId,
      },
    });
  }, []);

  if (!eventId) {
    return <Page withContactUs>Missing eventId...</Page>;
  }

  if (loading) {
    return (
      <Page>
        <LoadingSection />
      </Page>
    );
  }

  if (!data) {
    return (
      <Page>
        <LoadingSection />
        <div className={"center red-text"}>{error}</div>
      </Page>
    );
  }

  return (
    <Page>
      <div className="page-section-padding page-content">
        <EventAlbumImages albumImages={data.albumImages} />
      </div>
    </Page>
  );
};

export default EventPage;
