import React from "react";
import mobile from "is-mobile";
import { Masonry } from "masonic";

import "./Masonry.css";
import PicMeText from "./PicMeText";
import Video from "./Video";

const imageSrcs = [
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC_8648.jpeg",
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC_9020.jpeg",
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC_9005.jpeg",
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC07272.jpeg",
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC_8674.jpeg",
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC_7650.jpeg",
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC_9569.jpeg",
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC_9293.jpeg",
  "https://picme-albums.s3.eu-central-1.amazonaws.com/event_64edb6e95349ff85dace5aeb/album/DSC07523.jpeg",
];
const WhatsAppSection = () => {
  // const items = imageSrcs
  //   .map((src, i) => ({
  //     src,
  //   }))
  //   .filter(Boolean);

  return (
    <div className="bt bb pt40 pb40">
      <div
        // style={{ maxHeight: "500px" }}
        className="page-section-big-padding d-flex-mobile"
      >
        {mobile() ? (
          <div className="pb24 pt24">
            <div className="font-xxl  center pb24 flex-wrap">
              <span>ה-WhatsApp של </span>
              <PicMeText />
              <div className="thin">שולחים סלפי ומקבלים תמונות</div>
            </div>
            <Video url="https://picme-legal.s3.eu-central-1.amazonaws.com/whatsapp-demo.mov" />
            {/*<DemoVideo height="300px" />*/}
          </div>
        ) : (
          <div className="d-flex justify-content-between full-width">
            <div
              className="bg-purple-lightest font-xxl  center flex-1 d-flex-column justify-content-between ml40 pb40 p24"
              style={{ paddingTop: "100px" }}
            >
              <div className="font-xxl  center pb24 flex-wrap">
                <span>ה-WhatsApp של </span>
                <PicMeText />
                <div className="thin pt12">שולחים סלפי ומקבלים תמונות</div>
              </div>
              <div className="center">
                {/*<StarIcon />*/}
                <img className="pl12" src="/arrow-fill.svg" alt="arrow" />
                <img className="pl12" src="/arrow-fill.svg" alt="arrow" />
                <img src="/arrow-fill.svg" alt="arrow" />
              </div>
              <div>
                <div className="thin pt24 pb24">
                  אלפי אורחים כבר קיבלו את התמונות שלהם!
                </div>
                  <div className="pb24">
                       מה איתכם?
                  </div>
                {/*<div className={"masonic-container"}>*/}
                {/*  <div className={"masonic"}>*/}
                {/*    <Masonry*/}
                {/*      // Provides the data for our grid items*/}
                {/*      items={items}*/}
                {/*      // Adds 8px of space between the grid cells*/}
                {/*      columnGutter={2}*/}
                {/*      // Sets the minimum column width to 172px*/}
                {/*      columnWidth={88}*/}
                {/*      // Pre-renders 5 windows worth of content*/}
                {/*      overscanBy={20}*/}
                {/*      // This is the grid item component*/}
                {/*      render={({ data }) => (*/}
                {/*        <div className={"masonic-card"}>*/}
                {/*          <img*/}
                {/*            src={data.src}*/}
                {/*            alt={data.src}*/}
                {/*            className={"masonic-card-img"}*/}
                {/*          />*/}
                {/*        </div>*/}
                {/*      )}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
              <img
                style={{ height: "200px" }}
                src="/what-about-you.svg"
                alt="/what-about-you"
              />
            </div>
            <Video url="https://picme-legal.s3.eu-central-1.amazonaws.com/whatsapp-demo.mov" />
            {/*<Video width={"375"} height={"650px"} />*/}
          </div>
        )}
      </div>
    </div>
  );
};
export default WhatsAppSection;
