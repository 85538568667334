import React from "react";
import mobile from "is-mobile";

import "./Popup.css";

import CloseIcon from "./CloseIcon";

import copy from "copy-to-clipboard";
import QRCodeImage, { QRDownloadSVG, QRDownloadJPEG } from "./QRCodeImage";
import BroadcastEvent from "./BroadcastEvent";

import { USER_ROLE } from "./Login";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { toastSuccess } from "./toast";
import { Tooltip } from "react-tooltip";

const ShareEventPopupButton = ({ event }) => {
  const userRole = localStorage.getItem(USER_ROLE);

  const botLink = `https://picmee.co.il/s?event=${event.slug}`;
  const onCopyLinkClick = (e) => {
    e.preventDefault();
    copy(botLink);
    toastSuccess("הלינק הועתק");
  };

  const linkMessage_beforeEvent = `מתרגשים לראותכם באירוע שלנו🤩\nהבאנו צלם משו דה בסט אז דאגנו שתוכלו לקבל את כל התמונות שלכם! בקליק! \n\nלקבלת התמונות שלכם מהאירוע לחצו על הלינק\n${botLink} \n\nשולחים סלפי בוואטסאפ לבוט זיהוי הפנים PicMe ומקבלים את התמונות שלכם! זהו! אין צורך בהרשמה!`;
  const linkMessage_afterEvent = `תודה ששמחתם איתנו! מקווים שנהנתם כמונו🤩\nדאגנו שתוכלו לקבל את כל התמונות שלכם מהאירוע! בקליק!\n\nלקבלת התמונות שלכם מהאירוע לחצו על הלינק\n${botLink} \n\nשולחים סלפי בוואטסאפ לבוט זיהוי הפנים PicMe ומקבלים את התמונות שלכם! זהו! אין צורך בהרשמה!`;

  const onCopyLinkMessageClick = (e, text) => {
    e.preventDefault();
    copy(text);
    toastSuccess("ההודעה לדוגמא הועתקה");
  };

  return (
    <Popup
      trigger={<button className="primary-button">שיתוף האירוע</button>}
      modal
      closeOnDocumentClick={false}
    >
      {(close) => (
        <>
          <button
            className="popup-close-icon inline-button mr12"
            onClick={close}
          >
            <CloseIcon />
          </button>
          <div className="overflow-y-auto d-flex-column align-items-center justify-content-around">
            <div className="d-flex-mobile align-items-center justify-content-between pb40">
              <div
                className={`purple-dark-text bold center font-xl ${
                  mobile() ? "pt24" : ""
                }`}
              >
                {"שתפו את האירוע עם האורחים!"}
              </div>
            </div>

            <div className="d-flex-column font-l mb40 center thin">
              <div className="mb12">
                בלחיצה על לינק האירוע נפתח וואטסאפ לבוט של PicMe עם הודעת זיהוי
                לאירוע זה.
              </div>
              <div>
                בשליחת הודעת הזיהוי הבוט של PicMe מקשר את מספר הטלפון ממנו נשלחה
                ההודעה לאירוע.
              </div>
            </div>

            <div className="full-width d-flex-mobile align-items-center justify-content-between">
              <div
                style={{ paddingTop: mobile() ? "" : "104px" }}
                className="d-flex-column align-items-center pb40"
              >
                <a href={botLink} className="ShareEventPopupButton-link">
                  {botLink}
                </a>
                <button className="mt24" onClick={onCopyLinkClick}>
                  העתק
                </button>
              </div>

              <div>
                <QRCodeImage url={botLink} />
                <div className="d-flex align-items-center justify-content-around">
                  <QRDownloadSVG url={botLink} />
                  <QRDownloadJPEG url={botLink} />
                </div>
              </div>
            </div>

            <div className="full-width thin bt pt40 mt40 d-flex-mobile align-items-base justify-content-center">
              <div className="font-l">
                <div className={mobile() ? "" : "d-flex-column pb24"}>
                  <span>הודעה לדוגמא לשיתוף האירוע. שלחו הודעה זו לבעלי </span>
                  <span>האירוע על מנת שישלחו אותה לכל האורחים שלהם :)</span>
                </div>

                <Tooltip className="tooltip-max-width" id="beforeMessage">
                  {linkMessage_beforeEvent}
                </Tooltip>

                <div
                  className={`d-flex-mobile align-items-center pt12 ${
                    mobile() ? "" : "justify-content-between"
                  }`}
                >
                  <div
                    className="pl24 purple-dark-text"
                    data-tooltip-id="beforeMessage"
                  >
                    הודעה לשליחה לפני יום האירוע
                  </div>
                  <div
                    className={`d-flex ${
                      mobile()
                        ? "justify-content-center pt12 pb40"
                        : "justify-content-end"
                    }`}
                  >
                    <button
                      className=""
                      onClick={(e) =>
                        onCopyLinkMessageClick(e, linkMessage_beforeEvent)
                      }
                    >
                      העתק
                    </button>
                  </div>
                </div>

                <Tooltip className="tooltip-max-width" id="afterMessage">
                  {linkMessage_afterEvent}
                </Tooltip>

                <div
                  className={`d-flex-mobile align-items-center pt12 ${
                    mobile() ? "" : "justify-content-between"
                  }`}
                >
                  <div
                    className="pl24 purple-dark-text"
                    data-tooltip-id="afterMessage"
                  >
                    הודעה לשליחה לאחר יום האירוע
                  </div>
                  <div
                    className={`d-flex ${
                      mobile()
                        ? "justify-content-center pt12 pb40"
                        : "justify-content-end"
                    }`}
                  >
                    <button
                      className=""
                      onClick={(e) =>
                        onCopyLinkMessageClick(e, linkMessage_afterEvent)
                      }
                    >
                      העתק
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="thin bt pt40 mt40 d-flex-mobile align-items-base justify-content-between">
              <BroadcastEvent event={event} />
            </div>
          </div>
        </>
      )}
    </Popup>
  );
};

export default ShareEventPopupButton;
