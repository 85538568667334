import React, { useEffect } from "react";

import { toastSuccess } from "./toast";
import { Tooltip } from "react-tooltip";
import { EVENT_STATUS } from "./client-consts";
import Loading from "./loading";

import useFetch from "./useFetch";

import "./EventStatusBox.css";

const EventSearchStatus = ({
  event,
  eventSearchStatus,
  fetchEventSearchStatus,
}) => {
  if (!eventSearchStatus) {
    return <Loading />;
  }

  const data = eventSearchStatus;
  return (
    <div className="d-flex-column justify-content-between full-height">
      <div className="pb24">
        {event.status === EVENT_STATUS.ACTIVE && (
          <SearchStatusRow
            event={data}
            fetchEventSearchStatus={fetchEventSearchStatus}
          />
        )}
      </div>

      <div className="d-flex-mobile align-items-center justify-content-between">
        <div className="d-flex-column align-items-center small-counts ml24">
          <span className="font-xxl pb12">{data?.foundSelfiesCount}</span>
          <span className="font-l thin">סלפים נמצאו</span>
          <span className="font-l thin"> באלבום</span>
        </div>

        <div className="d-flex-column align-items-center small-counts">
          <span className="font-xxl pb12">{data?.sentImagesCount}</span>
          <span className="font-l thin">תמונות נשלחו</span>
          <span className="font-l thin">לאורחים</span>
        </div>
      </div>
    </div>
  );
};

const EventStatusBox = ({ label, value, actionButtons }) => {
  return (
    <div className="EventStatusBox">
      <div>
        <div className="font-xl pb24">{value}</div>
        <div className="font-l">{label}</div>
      </div>
      <div>{actionButtons}</div>
    </div>
  );
};

const SearchStatusRow = ({ event, fetchEventSearchStatus }) => {
  const { fetchData: startSearch } = useFetch({
    onSuccess: async () => {
      fetchEventSearchStatus();
    },
  });
  const onSearchClick = () => {
    startSearch({
      query: "startSearchMatches",
      params: {
        eventId: event._id,
        fullManualSearch: true,
      },
    });

    toastSuccess(
      "החל חיפוש סלפים באלבום האירוע. פעולה זו מסתיימת לאחר כ-20 דקות בממוצע לכל 1000 תמונות."
    );
  };

  const searchStatusText = event.lastSearchJob
    ? event.lastSearchJob.failedAt
      ? "קרתה שגיאה לא צפוייה בחיפוש. נא נסה שנית או צור קשר עם התמיכה."
      : event.lastSearchJob.lastFinishedAt
      ? "הסריקה האחרון הסתיימה בהצלחה!"
      : `ה-AI סורק את התמונות באלבום.${
          event.totalImagesQueuedForScan
            ? ` נסרקו ${event.totalImagesScanned || 0} מתוך ${
                event.totalImagesQueuedForScan
              } תמונות.`
            : ""
        }`
    : "ה-AI ממתין לקבלת סלפים ותמונות באלבום";
  const isSearching =
    event.lastSearchJob &&
    !event.lastSearchJob.failedAt &&
    !event.lastSearchJob.lastFinishedAt;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isSearching) {
        fetchEventSearchStatus();
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [isSearching]);

  return (
    <div className="full-width d-flex-column align-items-center justify-content-center">
      <div className="font-xl mb40 center">{searchStatusText}</div>
      <button
        className="d-flex align-items-center mb40"
        disabled={!!event.lastSearchJob && !event.lastSearchJob.lastFinishedAt}
        onClick={onSearchClick}
      >
        {/*<img className="pl16" src="/search.svg" alt="search" />*/}
        {isSearching ? <span>סורק</span> : <span>הרץ את ה-AI</span>}
        <Tooltip id="search-info-tooltip" />
        {searchStatusText ? (
          <>
            <div
              data-tooltip-id="search-info-tooltip"
              data-tooltip-content={searchStatusText}
            >
              {isSearching ? (
                <span className="mr12 loading-circle"></span>
              ) : null}
            </div>
          </>
        ) : null}
      </button>
    </div>
  );
};

export default EventSearchStatus;
