import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const POSITION = "bottom-center";

export const toastError = (text, { disableAutoClose, autoClose } = {}) => {
  toast.error(text, {
    transition: Slide,
    position: POSITION,
    hideProgressBar: true,
    autoClose: disableAutoClose ? false : autoClose || 5000,
    pauseOnFocusLoss: true,
    closeOnClick: true,
  });
};
export const toastSuccess = (text, { disableAutoClose, autoClose } = {}) => {
  toast.success(text, {
    transition: Slide,
    position: POSITION,
    hideProgressBar: true,
    autoClose: disableAutoClose ? false : autoClose || 5000,
    pauseOnFocusLoss: true,
    closeOnClick: true,
  });
};
