import React, { useState, useEffect } from "react";

import "./Loading.css";

const LoadingSection = () => {
  const [pastDelay, setPastDelay] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPastDelay(true);
    }, 200);

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, []);

  return pastDelay ? (
    <div className="page-section-content d-flex-column align-items-center justify-content-around">
      <div className="center">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  ) : null;
};
export default LoadingSection;
