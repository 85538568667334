import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import React, { useState } from "react";

import Input from "./Input";
import useFetch from "./useFetch";
import { toastError, toastSuccess } from "./toast";
import Toggle from "./Toggle";

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};
const EditEventPopupButton = ({ event, updateEventData }) => {
  const [name, setName] = useState(event.name);
  const [shouldUseEventName, setShouldUseEventName] = useState(
    event.shouldUseEventName
  );
  const [open, setOpen] = useState(false);

  const [eventDate, setEventDate] = useState(
    new Date(event.date).toISOString().substring(0, 10)
  );

  const { fetchData, error, loading } = useFetch({
    method: "post",
    onSuccess: () => {
      setOpen(false);
      updateEventData({ ...event, name, shouldUseEventName, date: eventDate });
      toastSuccess("האירוע נערך בהצלחה");
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      toastError("יש למלא את שם האירוע");
      return;
    }

    if (!isValidDate(new Date(eventDate))) {
      toastError("תאריך האירוע אינו תקין");
      return;
    }

    fetchData({
      query: "editEvent",
      params: {
        eventId: event._id,
        name,
        eventDate,
        shouldUseEventName,
      },
    });
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="inline-button">
        <img
          className="icon-large mr12 pointer"
          alt={"edit"}
          src={"./edit-icon.png"}
        />
      </button>

      <Popup
        open={open}
        className="small-popup"
        modal
        closeOnDocumentClick
        onClose={() => setOpen(false)}
      >
        <form className="d-flex-column align-items-center font-l">
          <div className="font-l pb32">עריכת פרטי האירוע</div>
          <Input
            type="date"
            label="תאריך האירוע"
            value={eventDate}
            onChange={(e) => {
              setEventDate(e.target.value);
            }}
          />
          <Input
            label="שם בעל האירוע \ שם הזוג"
            placeholder="האירוע של..."
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <div className="mb40 d-flex justify-content-start full-width">
            <Toggle
              toggleId={"shouldUseEventName"}
              className={"d-flex align-items-center disable-select"}
              isChecked={shouldUseEventName}
              onClick={() => setShouldUseEventName(!shouldUseEventName)}
              customLabelContent={
                <div className="pr12  thin">
                  {shouldUseEventName
                    ? "שם האירוע מוצג לאורחים"
                    : "שם האירוע מוסתר מהאורחים"}
                </div>
              }
            />
          </div>

          <div className="form-error">{error}</div>

          <div className="full-width">
            <button
              className="p16 d-flex align-items-center justify-content-center full-width"
              onClick={(e) => onSubmit(e)}
              disabled={loading || !name}
            >
              <span>אישור</span>
              {loading && <span className="mr12 loading-circle"></span>}
            </button>
          </div>
        </form>
      </Popup>
    </>
  );
};

export default EditEventPopupButton;
