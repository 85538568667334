import React from "react";

const TermsOfAgreenent = ({ isForMainSite }) => {
  window.location.replace(
    isForMainSite
      ? "https://picme-legal.s3.eu-central-1.amazonaws.com/Term+of+Agreement+Files/PicMe+-+Website+usage+term+of+agreement.pdf"
      : "https://picme-legal.s3.eu-central-1.amazonaws.com/Term+of+Agreement+Files/PicMe+-+Whatsapp+usage+term+of+agreement.pdf",
  );

  return null;
};

export default TermsOfAgreenent;
