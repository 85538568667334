import React from "react";

import "./EventStatusBadge.css";
const getEventLabel = (status) => {
  switch (status) {
    case "active":
      return "פעיל";
    case "future":
      return "עתידי";
    case "past":
      return "סגור";
    default:
      return "סטאטוס לא קיים";
  }
};
const EventStatusBadge = ({ status, size = "small" }) => {
  return (
    <div
      className={`EventStatusBadge EventStatusBadge-${status} EventStatusBadge-${size}`}
    >
      <span>{getEventLabel(status)}</span>
    </div>
  );
};

export default EventStatusBadge;
