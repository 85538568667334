import React, { useState } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Tooltip } from "react-tooltip";
import { toastSuccess, toastError } from "./toast";

import Input from "./Input";
import useFetch from "./useFetch";

const LeadPopupButton = ({ me }) => {
  return (
    <Popup
      className="small-popup"
      trigger={
        <button className="primary-button d-flex align-items-center">
          <span className="pl12">קבלו הצעה</span>
          <img src="/arrow.svg" alt="arrow" />
        </button>
      }
      modal
    >
      {(close) => <SendLeadForm onFinish={close} />}
    </Popup>
  );
};

const SendLeadForm = ({ onFinish }) => {
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const { fetchData, error, loading } = useFetch({
    method: "post",
    onSuccess: (resJson) => {
      toastSuccess(`תודה, נחזור אליך בהקדם!`);
      onFinish();
    },
  });

  const sendLead = (e) => {
    e.preventDefault();

    if (!phone) {
      toastError(`יש למלא מספר טלפון`);
      return;
    }

    fetchData({
      query: "sendLead",
      params: {
        role,
        name,
        phone,
      },
    });
  };
  return (
    <form className="d-flex-column align-items-center" onSubmit={sendLead}>
      <img className="logo-icon mb40" src="/picme-logo.svg" alt="logo" />

      <div className="center font-l pb32">
        <div className="pb12">זמינים תמיד בוואטסאפ</div>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://api.whatsapp.com/send?phone=972555524778"
          className="pb40 Link d-flex align-items-center justify-content-center"
        >
          <img className="icon" alt={"whatsapp"} src={"./whatsapp.svg"} />

          <span className="ph12">{"055-5524778"}</span>
        </a>

        <div>אבל אפשר גם להשאיר פרטים ואנחנו כבר נחזור אליכם ;)</div>
      </div>

      <Input
        label="אני"
        placeholder="צלמ/ת  מתחתנ/ת"
        value={role}
        onChange={(e) => {
          setRole(e.target.value);
        }}
      />
      <Input
        label="שם"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <Input
        type="number"
        label="טלפון"
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />

      <div className="form-error">{error}</div>

      <div>
        <button
          type="submit"
          className="d-flex align-items-center justify-content-center full-width"
        >
          <span>שליחה</span>
          {loading && <span className="mr12 loading-circle"></span>}
        </button>
      </div>
    </form>
  );
};

export default LeadPopupButton;
