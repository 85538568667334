import React from "react";

import "./Checkbox.css";

const Checkbox = ({ className, isChecked, onClick, customLabelContent }) => {
  return (
    <label className={`${className || ""} checkbox-container`}>
      <input type="checkbox" checked={isChecked} onChange={onClick} />
      <div className={`custom-checkbox ${isChecked ? "checked" : ""}`}>
        <div className="checkmark"></div>
      </div>

      {customLabelContent}
    </label>
  );
};

export default Checkbox;
