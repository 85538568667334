import React from "react";

const StarIcon = ({ light, className = "" }) => (
  <img
    className={`pl12 icon ${className}`}
    alt={"star"}
    src={light ? "./star.svg" : "./star-fill.svg"}
  />
);

export default StarIcon;
