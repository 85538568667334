import React from "react";

import "./Toggle.css";

const Toggle = ({
  className,
  isChecked,
  onClick,
  customLabelContent,
  toggleId,
}) => {
  return (
    <div className={`${className || ""} "toggle-container"`}>
      <input
        style={{ display: "none" }}
        type="checkbox"
        id={toggleId}
        checked={isChecked}
        onChange={onClick}
      />
      <label className="toggle-label" htmlFor={toggleId}>
        <div className="toggle-handle"></div>
      </label>
      {customLabelContent}
    </div>
  );
};

export default Toggle;
