import React, { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import mobile from "is-mobile";

import EventDetailsBox from "./EventDetailsBox";
import EventSearchResults from "./EventSearchResults";
import EventSearchStatus from "./EventSearchStatus";
import EventSettings from "./EventSettings";
import CloseFutureEvent from "./CloseFutureEvent";
import {
  SentSelfiesEventStatusBox,
  AlbumImagesEventStatusBox,
} from "./EventTopActions";
import LoadingSection from "./LoadingSection";
import useFetch from "./useFetch";
import { EVENT_STATUS } from "./client-consts";

import Page from "./Page";
import "./EventPage.css";

const EventPage = (props) => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");
  const [eventData, setEventData] = useState();

  const { fetchData, data, error } = useFetch();

  const { fetchData: fetchEventSearchStatusData, data: eventSearchStatus } =
    useFetch();

  const fetchEventSearchStatus = () => {
    fetchEventSearchStatusData({
      query: "getEventSearchStatusData",
      params: {
        eventId: eventId,
      },
    });
  };
  useEffect(() => {
    fetchEventSearchStatus();
  }, []);

  useEffect(() => {
    fetchEvent();
  }, []);

  function fetchEvent() {
    if (eventId) {
      fetchData({
        query: "getEvent",
        params: {
          eventId: eventId,
        },
      });
    }
  }
  useEffect(() => {
    if (data?.event) {
      setEventData(data.event);
    }
  }, [data]);

  if (!eventId) {
    return <Page withContactUs>Missing eventId...</Page>;
  }

  if (!eventData) {
    return (
      <Page>
        <LoadingSection />
        <div className={"center red-text"}>{error}</div>
      </Page>
    );
  }

  const event = eventData;

  return (
    <Page>
      <div className="page-section-padding mb24">
        {mobile() ? null : <EventPageTop />}

        <div className="EventDetailsBoxesContainer">
          <div className="EventDetailsGrid-FirstRow">
            <div className="EventGrid-LeftBorder">
              <EventDetailsBox event={event} updateEventData={setEventData} />
            </div>
            <div className="EventDetailsBox  EventGrid-LeftBorder d-flex-column align-items-center justify-content-around center">
              <SentSelfiesEventStatusBox event={event} />
            </div>
            <div className="EventDetailsBox d-flex-column align-items-center justify-content-around center">
              <AlbumImagesEventStatusBox
                event={event}
                fetchEventSearchStatus={fetchEventSearchStatus}
                refetchEvent={fetchEvent}
              />
            </div>
          </div>

          {event.status === EVENT_STATUS.ACTIVE ? (
            <div className="EventDetailsGrid-SecondRow EventGrid-TopBorder">
              <div className="EventDetailsBox EventGrid-LeftBorder">
                <EventSettings event={event} />
              </div>
              <div className="EventDetailsBox d-flex align-items-center justify-content-center">
                <EventSearchStatus
                  event={event}
                  fetchEventSearchStatus={fetchEventSearchStatus}
                  eventSearchStatus={eventSearchStatus}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {event.status === EVENT_STATUS.FUTURE ? (
        <div className="page-section-padding d-flex-column align-items-center">
          <CloseFutureEvent event={event} />
        </div>
      ) : null}

      <EventSearchResults event={event} />
    </Page>
  );
};

const EventPageTop = () => {
  return (
    <div className="pb40 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <img className="camera-icon ml16" src="/camera.svg" alt="camera" />
        <span className="font-xxl bold purple-dark-text">האירועים שלי</span>
      </div>
      <Link
        className="d-flex align-items-center"
        to={{
          pathname: "/",
        }}
      >
        <button className="ml16 inline-button font-xl">לכל האירועים</button>
        <img src="/arrow-fill.svg" alt="arrow" />
      </Link>
    </div>
  );
};
export default EventPage;
