import React from "react";

import "./LoginPackages.css";
import mobile from "is-mobile";

const STEPS = [
  {
    key: "LoginPackages_2",
    header: "בעלי אירוע",
    body: "חבילת PicMe חגיגית ב490 שקלים בלבד",
    imageSrc: "/wedding.svg",
    list: [
      "האורחים מקבלים את התמונות שלהם",
      "תמיכה מלאה מיום האירוע ועד לקבלת התמונות",
    ],
  },

  {
    key: "LoginPackages_1",
    header: "צלמים",
    body: "הוסיפו את PicMe לחבילת השירות שלכם",
    imageSrc: "/join-image.svg",
    specialBody: "הטבה מיוחדת למצטרפים חדשים!",
    list: [
      "צלמי PicMe מוכרים יותר אירועים!",
      "פרסום הסטודיו שלכם",
      "חופש מוחלט בתמחור לצד שלישי",
    ],
  },
];

const LoginPackages = () => {
  return (
    <div className="bb page-section-content LoginPackages-section page-section-big-padding d-flex-column pb40 pt40">
      <div
        className={`${
          mobile() ? "" : "mt40 pt40"
        } d-flex-column justify-content-center align-items-center`}
      >
        <div className="full-width d-flex-mobile align-items-base justify-content-between">
          {STEPS.map(({ key, header, body, specialBody, imageSrc, list }) => (
            <div
              style={{ backgroundImage: "url(/pricing-big-box.svg)" }}
              key={key}
              className="LoginPackages-item"
            >
              <div className="LoginPackages-header bold">{header}</div>
              <img
                className="LoginPackages-image"
                src={imageSrc}
                alt={imageSrc}
              />
              <div className="LoginPackages-body">{body}</div>
              <div className="LoginPackages-specialBody gevert-levin-text">
                {specialBody}
              </div>
              <div className="LoginPackages-list">
                <div>
                  {list.map((item, index) => (
                    <div className="d-flex pb12" key={index}>
                      <img
                        className="pl12 icon"
                        alt={"star"}
                        src={"./star-fill.svg"}
                      />
                      <span className="right">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoginPackages;
