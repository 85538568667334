import React, { useCallback, useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Line } from "rc-progress";

import useFetch from "./useFetch";
import "./UploadImagesWidget.css";

import { toastSuccess, toastError } from "./toast";
import mobile from "is-mobile";

const MAX_FILE_SIZE = 1024 * 1024 * 30;
const MAX_IMAGES_COUNT = 6000;

const UploadImagesWidget = ({
  event,
  fetchEventSearchStatus,
  closePopup,
  refetchEvent,
}) => {
  const retryCount = useRef(0);
  const uploadedChunks = useRef(0);
  const totalChunks = useRef();
  const chunksRef = useRef([]);

  const [uploading, setUploading] = useState(false);

  const { fetchData: startSearch } = useFetch({
    onSuccess: async () => {
      refetchEvent();
      fetchEventSearchStatus();
    },
  });

  const { fetchData: fetchAlbumImagesNames, data: albumImagesNames } =
    useFetch();

  useEffect(() => {
    fetchAlbumImagesNames({
      query: "getAlbumImagesNames",
      params: {
        eventId: event._id,
      },
    });
  }, []);

  const { upload: uploadImages, progress } = useFetch({
    method: "PUT",
    onSuccess: async (resJson, uploadData) => {
      if (resJson.data.error) {
        toastError(resJson.data.error);
        return;
      }
      uploadedChunks.current = (uploadedChunks.current || 0) + 1;
      console.log(
        `${
          totalChunks.current > 1
            ? `(${uploadedChunks.current}/${totalChunks.current}) `
            : ""
        }${resJson.data.imagesUploadedCount || 0} תמונות הועלו בהצלחה`
      );

      if (uploadedChunks.current === totalChunks.current) {
        setUploading(false);
        uploadedChunks.current = 0;
        totalChunks.current = 0;

        toastSuccess(`התמונות הועלו בהצלחה`, { disableAutoClose: true });

        await startSearch({
          query: "startSearchMatches",
          params: {
            eventId: event._id,
          },
        });
        toastSuccess(
          "החלה סריקת התמונות שהועלו. זמן משוער של סריקה ראשונית  הוא כ-20 דקות לכל 1000 תמונות. שלב זה מאפשר חיפוש סלפי באלבום תוך שניות!",
          { disableAutoClose: true }
        );
        closePopup();
      } else {
        await uploadChunk();
      }
    },
    onError: (e) => {
      console.log("ERROR IN IMAGES UPLOAD", e);
      retryCount.current = (retryCount.current || 0) + 1;

      if (retryCount.current > 10) {
        retryCount.current = 0;
        toastError("קרתה שגיאה בהעלאת התמונות", { disableAutoClose: true });
        throw new Error("ERROR IN IMAGES UPLOAD", e);
      } else {
        setTimeout(async () => {
          await uploadChunk();
        }, 30000);
      }
    },
  });

  const onDrop = useCallback(async (files) => {
    console.log("files", files);
    if (!files?.length) {
      toastSuccess(`התמונות להעלאה חייבות להיות מסוג JPEG`);
      return;
    }
    const uploadedImagesCount = event?.albumImagesCount || 0;
    if (uploadedImagesCount >= MAX_IMAGES_COUNT) {
      toastError("לא ניתן להעלות יותר מ-6000 תמונות לאלבום");
      return;
    }

    if (files.find((file) => file.size > MAX_FILE_SIZE)) {
      toastError("לא ניתן להעלות תמונה בגודל שעולה על 30MB");
      return;
    }

    const uploadedImagesOriginalNames = albumImagesNames || [];
    console.log("uploadedImagesOriginalNames", uploadedImagesOriginalNames);

    const canUploadFilesCount = MAX_IMAGES_COUNT - uploadedImagesCount;
    const filesToUpload = files
      .filter(({ name }) => !uploadedImagesOriginalNames.includes(name))
      .slice(0, canUploadFilesCount);

    if (!filesToUpload?.length) {
      toastSuccess(`תמונות אלו כבר הועלו לאירוע זה בהצלחה`);
      return;
    }

    setUploading(true);
    const chunks = [];
    let currentChunkSize = 0;
    let currentChunkFiles = [];

    // Split files into chunks based on maximum chunk size (400MB)
    for (const file of filesToUpload) {
      const fileSize = file.size;
      if (currentChunkSize + fileSize <= 80 * 1024 * 1024) {
        currentChunkSize += fileSize;
        currentChunkFiles.push(file);
      } else {
        chunks.push(currentChunkFiles);
        currentChunkSize = fileSize;
        currentChunkFiles = [file];
      }
    }

    if (currentChunkFiles.length > 0) {
      chunks.push(currentChunkFiles);
    }

    totalChunks.current = chunks.length;
    chunksRef.current = chunks;

    // Send chunks to the server
    // for (const chunk of chunks) {
    //   const formData = new FormData();
    //   chunk.forEach((file) => {
    //     formData.append("images", file);
    //   });
    //   // Modify the uploadImages function to handle individual requests
    //   await uploadImages({
    //     query: "uploadImages",
    //     params: {
    //       eventId,
    //     },
    //     body: formData,
    //   });
    // }
    uploadChunk();
  }, []);

  const uploadChunk = async () => {
    const chunk = chunksRef.current[uploadedChunks.current];
    const formData = new FormData();
    chunk.forEach((file) => {
      formData.append("images", file);
    });
    // Modify the uploadImages function to handle individual requests
    await uploadImages({
      query: "uploadImages",
      params: {
        eventId: event._id,
      },
      body: formData,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png", ".JPEG", ".PNG"],
    },
  });
  const progressPercent =
    totalChunks.current > 1
      ? Math.ceil(
          (uploadedChunks.current / totalChunks.current) * 100 +
            (1 / totalChunks.current) * progress
        )
      : progress || 0;

  return (
    <div className="UploadImagesWidget" {...getRootProps()}>
      <input {...getInputProps()} accept="image/png" />
      <div className="font-l d-flex align-items-center justify-content-center">
        {uploading ? (
          <div className="d-flex-column">
            <div className="d-flex align-items-center mb24">
              <span className="pl12">מעלה תמונות לענן</span>
              <span>{`${
                progressPercent === 100 ? 99 : progressPercent || 1
              }%`}</span>
            </div>

            <Line
              percent={progressPercent}
              strokeWidth={8}
              trailWidth={8}
              strokeColor="#7f52ff"
            />

            <div
              style={{ maxWidth: "200px" }}
              className="d-flex align-items-center mt24 center thin font-m"
            >
              <span className="pl12">
                העלאת כל 100 תמונות עשויה להימשך עד כ-3 דקות
              </span>
            </div>
          </div>
        ) : isDragActive ? (
          <div>שחרר את התמונות</div>
        ) : (
          <div className="d-flex-column justify-content-center align-items-center">
            <div className="pb32">
              <img src="upload.svg" alt="upload" />
            </div>
            {mobile() ? (
              <div className="">לחץ להעלאת תמונות</div>
            ) : (
              <>
                <div className="pb24">גרור לכאן תמונות</div>
                <div className="">או לחץ להעלאה</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImagesWidget;
