import React, { useState } from "react";

import mobile from "is-mobile";

import "./EventSettings.css";
import useFetch from "./useFetch";
import Toggle from "./Toggle";

const EventSettings = ({ event }) => {
  const [checked, setChecked] = useState(event.withAutoSend);

  const { fetchData: updateEventAutoSend } = useFetch({ method: "post" });

  const handleChange = () => {
    setChecked(!checked);

    updateEventAutoSend({
      query: "updateEventAutoSend",
      params: {
        eventId: event._id,
        withAutoSend: !checked,
      },
    });
  };

  const titleText = checked ? "שליחה אוטומטית מופעלת" : "שליחה אוטומטית כבויה";
  const explanationText = checked
    ? "האורחים מקבלים לינק עם התמונות שלהם באופן אוטומטי."
    : "עוברים על תוצאות החיפוש ובסיום מפעילים את השליחה האוטומטית.";
  return (
    <>
      <div className="pb40 bold font-xxl purple-text">
        ה-AI של PicMe עובד באופן אוטומטי לגמרי!
      </div>
      <div className="pb24 font-xl d-flex align-items-start">
        <img
          className="pl12 icon icon-pt"
          alt={"star"}
          src={"./star-fill.svg"}
        />
        <div className="thin">
          ה-AI רץ באופן אוטומטי בהעלאת תמונות ובקבלת סלפים חדשים.
        </div>
      </div>
      <div className="pb40 font-xl d-flex align-items-start">
        <img
          className="pl12 icon icon-pt"
          alt={"star"}
          src={"./star-fill.svg"}
        />
        <div className="d-flex-column">
          <div className="thin">
            ניתן לעבור על תוצאות החיפוש לפני הפעלת השליחה האוטומטית!
          </div>
          {/*<div className="pt12 thin"></div>*/}
        </div>
      </div>

      <Toggle
        toggleId={"autoSendToggle"}
        className={"d-flex-mobile EventSettings-AutoSent disable-select"}
        isChecked={checked}
        onClick={handleChange}
        customLabelContent={
          <div className={`thin ${mobile() ? "pt12" : "pr12"}`}>
            <div className={`font-xl ${checked ? "purple-text" : "grey-text"}`}>
              {titleText}
            </div>
            <div className="pt12 font-l">{explanationText}</div>
          </div>
        }
      />
    </>
  );
};
export default EventSettings;
