import React, { useEffect, useRef } from "react";
import { saveAs } from "file-saver";

import { toastSuccess } from "./toast";
import QRCode from "qrcode";

var qrOptins = {
  errorCorrectionLevel: "H",
  margin: 3,
  width: 250,
  color: {
    dark: "#000000",
    light: "#f3f0fc",
  },
};

const QRCodeImage = ({ url }) => {
  const imageRef = useRef();
  useEffect(() => {
    QRCode.toDataURL(url, qrOptins, function (err, imageSrc) {
      if (err) throw err;

      imageRef.current.src = imageSrc;
    });
  }, [url]);

  return (
    <div className="d-flex-column">
      <img
        className="mb8"
        // src={}  TODO: add loading image
        ref={imageRef}
        alt="QR Code"
      />
    </div>
  );
};

const generateQRSvgString = async (text) => {
  try {
    const svgString = await QRCode.toString(text, {
      type: "svg",
      errorCorrectionLevel: "H",
    });
    return svgString;
  } catch (error) {
    console.error(error);
  }
};
export const QRDownloadSVG = ({ url }) => {
  const handleDownload = async () => {
    const svgString = await generateQRSvgString(url);
    const svgBlob = new Blob([svgString], {
      type: "image/svg+xml;charset=utf-8",
    });
    saveAs(svgBlob, "qr-code.svg");

    toastSuccess("החלה הורדת הקובץ");
  };

  return (
    <button className="inline-button" onClick={handleDownload}>
      הורד SVG
    </button>
  );
};

export const QRDownloadJPEG = ({ url }) => {
  const handleDownload = async () => {
    const svgString = await generateQRSvgString(url);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const svgImage = new Image();
    svgImage.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
      svgString,
    )}`;
    svgImage.onload = function () {
      canvas.width = svgImage.width;
      canvas.height = svgImage.height;
      context.drawImage(svgImage, 0, 0);
      canvas.toBlob(function (blob) {
        const jpegBlob = new Blob([blob], { type: "image/jpeg" });
        saveAs(jpegBlob, "qr-code.jpeg");
      }, "image/jpeg");
    };

    toastSuccess("החלה הורדת הקובץ");
  };

  return (
    <button className="inline-button pb12" onClick={handleDownload}>
      הורד JPEG
    </button>
  );
};

export default QRCodeImage;
