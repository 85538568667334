import React from "react";

import ShareEventPopupButton from "./ShareEventPopupButton";
import UploadToAlbumPopupButton from "./UploadToAlbumPopupButton";
import { EVENT_STATUS } from "./client-consts";

import "./EventStatusBox.css";
import { useNavigate } from "react-router-dom";

export const SentSelfiesEventStatusBox = ({ event }) => {
  return (
    <EventStatusBox
      label={"אורחים שלחו סלפי"}
      value={event?.selfieCount}
      actionButtons={
        [EVENT_STATUS.ACTIVE, EVENT_STATUS.FUTURE].includes(event.status) && (
          <SelfiesBoxActions event={event} />
        )
      }
    />
  );
};

export const AlbumImagesEventStatusBox = ({
  event,
  fetchEventSearchStatus,
  refetchEvent,
}) => {
  const navigate = useNavigate();

  return (
    <EventStatusBox
      label={"תמונות באלבום"}
      value={event?.albumImagesCount}
      valueAction={
        event?.albumImagesCount > 0
          ? () => {
              navigate({
                pathname: `/event-album`,
                search: `eventId=${event._id}`,
              });
            }
          : null
      }
      actionButtons={
        [EVENT_STATUS.ACTIVE, EVENT_STATUS.FUTURE].includes(event.status) && (
          <AlbumImagesBoxActions
            event={event}
            fetchEventSearchStatus={fetchEventSearchStatus}
            refetchEvent={refetchEvent}
          />
        )
      }
    />
  );
};

const EventStatusBox = ({ label, value, actionButtons, valueAction, what }) => {
  return (
    <>
      <div
        className={`EventStatusNumber ${
          valueAction ? "pointer purple-on-hover" : ""
        }`}
        onClick={valueAction}
      >
        {value}
      </div>
      <div className="font-xl mb12">{label}</div>
      <div>{actionButtons}</div>
    </>
  );
};
const SelfiesBoxActions = ({ event }) => {
  return <ShareEventPopupButton event={event} />;
};

const AlbumImagesBoxActions = ({
  event,
  fetchEventSearchStatus,
  refetchEvent,
}) => {
  return (
    <UploadToAlbumPopupButton
      event={event}
      refetchEvent={refetchEvent}
      fetchEventSearchStatus={fetchEventSearchStatus}
    />
  );
};
