import React from "react";
import { Masonry } from "masonic";

import Checkbox from "./Checkbox";
import { SentStatusIndicator, shouldSendMatch } from "./SearchResults";

import "./UserPage.css";

import "./Masonry.css";
import mobile from "is-mobile";

const USER_CARD = "user_card_id";

const SelfiePics = ({ selfie, cleanWrongMatch }) => {
  const items = [
    selfie.userCard && selfie.userCard.isEnabled
      ? {
          id: USER_CARD,
          card: selfie.userCard,
        }
      : null,
  ]
    .concat(
      selfie.matches.map((match, i) => ({
        index: i,
        id: i,
        cleanWrongMatch,
        match,
        selfieId: selfie._id,
        isLastItem: i === selfie.matches?.length - 1,
      }))
    )
    .filter(Boolean);
  return (
    <div className={"masonic-container"}>
      <div className={"masonic"}>
        <Masonry
          // Provides the data for our grid items
          items={items}
          // Adds 8px of space between the grid cells
          columnGutter={16}
          // Sets the minimum column width to 172px
          columnWidth={350}
          // Pre-renders 5 windows worth of content
          overscanBy={5}
          // This is the grid item component
          render={ImageCardOrUserCardRenderer}
        />
      </div>
    </div>
  );
};
export default SelfiePics;

const ImageCardOrUserCardRenderer = ({ data }) => {
  if (data.id === USER_CARD) {
    return <UserCardForGuest card={data.card} />;
  } else {
    return <ImageCard data={data} />;
  }
};
const ImageCard = ({
  data: { selfieId, match, cleanWrongMatch, isLastItem, index },
}) => {
  const { isRemoved, isAdded } = match;
  const shouldSend = shouldSendMatch({ isRemoved, index, isAdded });
  return (
    <>
      <div
        className={"masonic-card"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!match.isSent) {
            cleanWrongMatch(selfieId, match.albumImage.key, shouldSend);
          }
        }}
      >
        <img
          className={"masonic-card-img"}
          alt={match.albumImage.url}
          src={match.albumImage.url}
        />
        {match.isSent ? null : (
          <div className="masonic-card-action">
            <Checkbox
              isChecked={shouldSend}
              onClick={(e) => {
                cleanWrongMatch(selfieId, match.albumImage.key, shouldSend);
              }}
            />
          </div>
        )}

        <SentStatusIndicator
          _key={index + "_" + match.albumImage.key}
          isSent={match.isSent}
          statusArray={match.status}
          messageId={match.messageId}
          shouldSend={shouldSend}
          isFromMyPics={true}
        />
      </div>
      {isLastItem && mobile() ? <div className="pt24 pb24"></div> : null}
    </>
  );
};

export const UserCardForGuest = ({ card }) => {
  const {
    phone,
    webLink,
    facebookLink,
    instagramLink,
    youtubeLink,
    vimeoLink,
    imageLink,
  } = card || {};

  return (
    <div
      className="user-card"
      style={{ backgroundImage: "url(/how-it-works-box.svg)" }}
    >
      {imageLink ? (
        <div className={"p32 bb"}>
          <img className={"masonic-card-img"} src={imageLink} alt={imageLink} />
        </div>
      ) : null}
      <div className="">
        {card.name ? (
          <div className="center font-xxl pv32 bb">{card.name}</div>
        ) : null}
        {phone ? <div className="center font-xxl pv32 bb">{phone}</div> : null}
        <div className="d-flex flex-wrap justify-content-center p32">
          {webLink ? (
            <CardLink link={webLink} name="אתר" icon="web-icon.svg" />
          ) : null}
          {facebookLink ? (
            <CardLink
              link={facebookLink}
              name="פייסבוק"
              icon="facebook-icon.svg"
            />
          ) : null}
          {instagramLink ? (
            <CardLink
              link={instagramLink}
              name="אינסטגרם"
              icon="instagram-icon.svg"
            />
          ) : null}
          {youtubeLink ? (
            <CardLink
              link={youtubeLink}
              name="YouTube"
              icon="youtube-icon.svg"
            />
          ) : null}
          {vimeoLink ? (
            <CardLink link={vimeoLink} name="Vimeo" icon="vimeo-icon.svg" />
          ) : null}
        </div>
      </div>
    </div>
  );
};
const CardLink = ({ link, icon, name }) => {
  return (
    <a
      href={link.includes("http") ? link : "//" + link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className="ml12 icon-xlarge" src={icon} alt={name} />
    </a>
  );
};
