import React, { useEffect, useState } from "react";
import mobile from "is-mobile";

import useFetch from "./useFetch";

import { toastSuccess, toastError } from "./toast";
import Loading from "./loading";
import Input from "./Input";

const UploadImagesFromLyncpix = ({
  event,
  fetchEventSearchStatus,
  closePopup,
  refetchEvent,
}) => {
  const [externalEventId, setExternalEventId] = useState();

  const { fetchData: startSearch } = useFetch({
    onSuccess: async () => {
      refetchEvent();
      fetchEventSearchStatus();
    },
  });

  const {
    fetchData: uploadFromLyncPix,
    error,
    data,
    loading: uploading,
  } = useFetch({
    method: "POST",
  });

  useEffect(() => {
    if (error) {
      console.log("ERROR IN IMAGES UPLOAD", error);
      toastError("קרתה שגיאה בהעלאת התמונות", { disableAutoClose: true });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (data.foundImagesCount === 0) {
        toastError("לא נמצאו תמונות. בדוק שהקוד שהוזן תקין.", {
          disableAutoClose: true,
        });
      } else {
        toastSuccess(data.imagesUploadedCount + ` תמונות הועלו בהצלחה`, {
          disableAutoClose: true,
        });
      }

      if (data.imagesUploadedCount > 0) {
        startSearch({
          query: "startSearchMatches",
          params: {
            eventId: event._id,
          },
        });
        toastSuccess(
          "החלה סריקת התמונות שהועלו. זמן משוער של סריקה ראשונית  הוא כ-20 דקות לכל 1000 תמונות. שלב זה מאפשר חיפוש סלפי באלבום תוך שניות!",
          { disableAutoClose: true }
        );

        closePopup();
      }
    }
  }, [data]);
  const onSubmit = () => {
    if (!externalEventId) {
      return;
    }
    uploadFromLyncPix({
      method: "POST",
      query: "uploadImagesToLyncpix",
      params: {
        eventId: event._id,
        externalEventId,
      },
    });
  };

  return (
    <div className="d-flex-mobile align-items-center">
      <Input
        className={mobile() ? "" : "ml12"}
        value={externalEventId}
        setValue={setExternalEventId}
        placeholder="הכנס את קוד האירוע מ - Lyncpix"
      />
      <div className={`d-flex ${mobile() ? "full-width" : ""}`}>
        <button
          disabled={!externalEventId || uploading}
          onClick={onSubmit}
          className={`justify-content-center button d-flex ${mobile() ? "mt12 full-width" : ""}`}
        >
          <span>{uploading ? "מייבא" : "ייבוא"}</span>
          {uploading && <span className="loading-circle mr12"></span>}
        </button>
      </div>
    </div>
  );
};

export default UploadImagesFromLyncpix;
