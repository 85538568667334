import * as Sentry from "@sentry/react";

import { Slide, ToastContainer } from "react-toastify";
import ReactDOM from "react-dom/client";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useSearchParams,
} from "react-router-dom";

import EventsPage from "./EventsPage";
import EventPage from "./EventPage";
import Signup from "./Signup";
import Login, { LOGIN_TOKEN_KEY } from "./Login";
import ErrorPage from "./ErrorPage";
import TermsOfAgreenent from "./TermsOfAgreenent";
import MyPicsPage from "./MyPicsPage";

import "./App.css";
import "react-tooltip/dist/react-tooltip.css";
import UserPage from "./UserPage";
import EventAlbumImagesPage from "./EventAlbumImagesPage";
import SupportPage from "./SupportPage";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://13c5c8bd3c2b42f3acfbb6e0e5a09585@o4504978663538688.ingest.sentry.io/4504978718457856",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const RedirectToEvent = () => {
  const [searchParams] = useSearchParams();
  const eventSlug = searchParams.get("event");
  window.location.href = `https://api.whatsapp.com/send?phone=972555530749&text=היי אני רוצה לקבל את התמונות שלי מהאירוע! הקוד שלי: (${eventSlug})`;
  return null;
};

const RouteLoggedIn = ({ component }) => {
  const loginToken = localStorage.getItem(LOGIN_TOKEN_KEY);
  return loginToken ? component : <Navigate to={"/login"} />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        exact
        element={<RouteLoggedIn component={<UserPage />} />}
        path="/me"
        errorElement={ErrorPage}
      />
      <Route
        exact
        element={<RouteLoggedIn component={<EventPage />} />}
        path="/event"
        errorElement={ErrorPage}
      />
      <Route
        exact
        element={<RouteLoggedIn component={<EventAlbumImagesPage />} />}
        path="/event-album"
        errorElement={ErrorPage}
      />
      <Route
        element={<RouteLoggedIn component={<EventsPage />} />}
        path="/"
        errorElement={ErrorPage}
      />
      <Route
        element={<RouteLoggedIn component={<SupportPage />} />}
        path="/support"
        errorElement={ErrorPage}
      />
      {/* No auth routes */}
      <Route
        element={<Signup />}
        path="/secret-signup"
        errorElement={ErrorPage}
      />
      <Route
        element={<MyPicsPage />}
        path="/my-pics"
        errorElement={ErrorPage}
      />
      <Route element={<Login />} path="/login" errorElement={ErrorPage} />

      <Route
        path="/terms-site"
        element={<TermsOfAgreenent isForMainSite={true} />}
      />
      <Route
        path="/terms-wa"
        element={<TermsOfAgreenent isForMainSite={false} />}
      />
      <Route path="/s" element={<RedirectToEvent />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.Fragment>
    <RouterProvider router={router} />
    <ToastContainer rtl={true} transition={Slide} />
  </React.Fragment>
);
