import React from "react";

const Input = ({ label, setValue, ...rest }) => {
  return (
    <div className="d-flex-column full-width">
      {label ? (
        <div className="label">
          <label htmlFor="input">{label}</label>
        </div>
      ) : null}
      <input
        id="input"
        className="input mb24"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        {...rest}
      ></input>
    </div>
  );
};

export default Input;
